import React from 'react';
import { Linkedin, Twitter } from 'react-feather';
import { conf } from 'config';

import './FooterComponent.scss';

type Props = Record<string, never>;
type State = Record<string, never>;
export default class FooterComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    return (
      <footer id="footerComponent" className="container-fluid pb-3 pt-3">
        <div className="row">
          <div className="col-12 col-md-3">
            <p>
              <img className="mt-3 mb-3" src="/images/logo-grayscale.svg" height={50} alt="semi" />
            </p>
            <address className="mb-2">
              673 South Milpitas Blvd.
              <br />
              Milpitas, CA 95035, USA
            </address>
            <p className="mb-2">
              <a href="mailto:semihq@semi.org">semihq@semi.org</a>
            </p>
            <p className="mb-2">©2022 SEMI All rights reserved.</p>
            <p className="mb-2">
              <a href="https://www.semi.org/en/About/TermsOfUse" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{' '}
              |{' '}
              <a href="https://www.semi.org/en/semi-privacypolicy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </p>
            <p className="mb-2">
              ICP number of <a href="http://www.miibeian.gov.cn/">沪ICP备06022522号</a>
            </p>
            <p className="mb-2">
              <small id="buildVersion" className="text-muted">
                Build {conf.buildVersion}
              </small>
            </p>
            <div className="mb-5 mt-3 w-100">
              <a
                className="nav-link py-md-0 noSelect float-left"
                href="https://twitter.com/SEMIconex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
              <a
                className="nav-link py-md-0 noSelect float-left"
                href="https://www.linkedin.com/company/semi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="row mt-3">
              <div className="col-12 col-sm-6 col-md-2">
                <h6 className="text-uppercase">ABOUT SEMI & MEMBERSHIP</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/about-semi-membership/member-benefits"
                    >
                      SEMI Membership
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/semi-organization/mission-vision"
                    >
                      SEMI: The Organization
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.semi.org/en/news-resources/press">
                      News, Media & Press
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.semi.org/blogs">
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.semi.org/en/meet-at-semi">
                      Newsletters
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-sm-6 col-md-2">
                <h6 className="text-uppercase">INDUSTRIES & COMMUNITIES</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/industry-groups/smart-initiatives"
                    >
                      Smart Initiatives
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/communities/about-communities"
                    >
                      Communities
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/industry-groups/committees"
                    >
                      Committees
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/industry-groups/partnerss"
                    >
                      Partners
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-sm-6 col-md-2">
                <h6 className="text-uppercase">ADVOCACY & WORKFORCE</h6>
                <ul>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.semi.org/en/global-advocacy">
                      Global Advocacy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.semi.org/en/workforce-development">
                      Workforce Development
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-sm-6 col-md-2">
                <h6 className="text-uppercase">PRODUCTS & SERVICES</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/products-services/market-data"
                    >
                      Market Data
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/products-services/standards"
                    >
                      Standards
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.semi.org/en/resources/library">
                      Resource Library
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/resources/member-directory"
                    >
                      Member Directory
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/resources/window-on-china"
                    >
                      Window on China
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-2">
                <h6 className="text-uppercase">EXPOSITIONS & EVENTS</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/expositions-events/expositions"
                    >
                      Expositions
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.semi.org/en/expositions-events/events"
                    >
                      Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
