import React from 'react';
import Util from 'util/util';

import './TextAreaComponent.scss';

type Props = {
  label: string;
  value: string;
  rows: number;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  type?: string;
  id?: string;
  maxLength?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};
type State = object;
export default class TextAreaComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label);

    return (
      <div className="textAreaComponent form-row pr-2 pl-2 mb-2">
        <div className="form-group w-100">
          <label htmlFor={id} className={this.props.error ? 'text-danger' : ''}>
            {this.props.label}
          </label>
          <textarea
            id={id}
            name={id}
            placeholder={this.props.placeholder || this.props.label}
            className={'form-control w-100 pl-3 pr-3 ' + (this.props.error ? 'is-invalid' : '')}
            onChange={this.props.onChange}
            value={this.props.value}
            rows={this.props.rows}
            maxLength={this.props.maxLength}
            disabled={this.props.disabled}
          />
          <div className={this.props.error ? '' : 'd-none'}>
            <small className="text-danger">{this.props.error}</small>
          </div>
        </div>
      </div>
    );
  }
}
