import React from 'react';
import SpinnerComponent from 'components/SpinnerComponent';
import ErrorComponent from 'components/common/ErrorComponent';
import './LoadingErringComponent.scss';

type Props = React.PropsWithChildren<{
  error: string;
  loading: boolean;
}>;
type State = Record<string, never>;
export default class LoadingErringComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    if (this.props.error) {
      return (
        <div className="emptySpacer pt-5">
          <ErrorComponent message={this.props.error} />
        </div>
      );
    }

    if (this.props.loading) {
      return (
        <div className={'emptySpacer'}>
          <div className="spinnerUp1">
            <SpinnerComponent visible={true} />
          </div>
        </div>
      );
    }

    return <div className="loadingErringComponent">{this.props.children}</div>;
  }
}
