import React from 'react';
import {
  ProfileModel,
  CommitteeModel,
  StandardsBallotsVoteModel,
  CommitteeCycleModel,
  StandardsBallotLineItemModel,
  CommitteeCycleVotes,
} from 'services/Models';
import CardComponent from 'components/common/CardComponent';
import { conf } from 'config';
import Util from 'util/util';
import CardFieldRowComponent from 'components/common/CardFieldRowComponent';
import LoadingErringComponent from 'components/common/LoadingErringComponent';
import { ProfileService } from 'services/ProfileService';
import { HttpError } from 'services/HttpService';
import { HTTP_ERROR_CODE } from 'services/HttpTypes';
import { Route } from 'react-router-dom';
import { BallotsService } from 'services/BallotsService';
import { Switch } from 'react-router';
import StandardsBallotsFormCardComponent from './StandardsBallotsFormCardComponent';

import './MyStandardsBallotsComponent.scss';

type CommitteeCycleGroups = {
  submittedCommitteeCycles: CommitteeCycleModel[];
  unsubmittedCommitteeCycles: CommitteeCycleModel[];
};

type Props = {
  committeeCycles: CommitteeCycleModel[];
};
type State = {
  profile: ProfileModel | null;
  committeeCycleVotes: CommitteeCycleVotes[] | null;
  httpError: string;
};

export default class MyStandardsBallotsComponent extends React.Component<Props, State> {
  public state: State = {
    profile: null,
    committeeCycleVotes: null,
    httpError: '',
  };

  public componentDidMount(): void {
    this.fetchProfile();
    this.fetchVotes();
  }

  private fetchProfile = (): void => {
    this.setState({ profile: null, httpError: '' });

    ProfileService.getCustomer()
      .then((profile: ProfileModel): void => {
        this.setState({
          profile: profile,
        });
      })
      .catch((httpError: HttpError): void => {
        if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
          this.setState({ httpError: 'Not Login' });
        } else {
          this.setState({ httpError: httpError.toString() });
        }
      });
  };

  private fetchVotes = (): void => {
    this.setState({ committeeCycleVotes: null, httpError: '' });

    BallotsService.GetCommitteeCycleVotes()
      .then((votes: CommitteeCycleVotes[]): void => {
        this.setState({
          committeeCycleVotes: votes,
        });
      })
      .catch((httpError: HttpError): void => {
        if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
          this.setState({ httpError: 'Not Login' });
        } else {
          this.setState({ httpError: httpError.toString() });
        }
      });
  };

  private isStandardsBallotRequired = (profile: ProfileModel, committeeCycle: CommitteeCycleModel): boolean => {
    const standardsCommittees = profile.committee.standards;

    return standardsCommittees.some(
      (committee: CommitteeModel): boolean =>
        committee.committeename === committeeCycle.committee && committee.status === conf.active
    );
  };

  private groupCommitteeCycles = (
    committeeCycleVotes: CommitteeCycleVotes[],
    committeeCycles: CommitteeCycleModel[]
  ): CommitteeCycleGroups => {
    const submittedCommitteeCycle: CommitteeCycleModel[] = [];
    const unsubmittedCommitteeCycle: CommitteeCycleModel[] = [];

    committeeCycles.forEach((committeeCycle: CommitteeCycleModel): void => {
      if (
        committeeCycleVotes.some((v) => v.committee === committeeCycle.committee && v.period === committeeCycle.period)
      ) {
        submittedCommitteeCycle.push(committeeCycle);
      } else {
        unsubmittedCommitteeCycle.push(committeeCycle);
      }
    });

    return {
      submittedCommitteeCycles: submittedCommitteeCycle,
      unsubmittedCommitteeCycles: unsubmittedCommitteeCycle,
    };
  };

  private getCommitteeCycleVote = (
    committeeCycleVotes: CommitteeCycleVotes[],
    committeeCycle: CommitteeCycleModel
  ): StandardsBallotsVoteModel[] => {
    const committeeCycleVote = committeeCycleVotes.find(
      (cv) => cv.committee === committeeCycle.committee && cv.period === committeeCycle.period
    );

    return committeeCycle.ballotLineData.map(
      (b: StandardsBallotLineItemModel): StandardsBallotsVoteModel =>
        BallotsService.findLineItemVote(b, committeeCycleVote ? committeeCycleVote.votedata : [])
    );
  };

  private closeEdits = (): void => {
    // No need to fetch profile again. It doesn't change here.
    this.fetchVotes();
    Util.navToRoute(conf.hash.myStandardsBallots);
  };

  private saveVote = (votes: StandardsBallotsVoteModel[]): Promise<void> => {
    return BallotsService.SaveVotes(votes)
      .then((): void => {
        this.closeEdits();
      })
      .catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  public render(): JSX.Element {
    if (this.state.httpError || this.state.profile === null || this.state.committeeCycleVotes === null) {
      if (this.state.httpError === 'Not Login') {
        return (
          <React.Fragment>
            <div className="emptySpacer pt-5">
              <div id="accountComponent" className="container mt-3">
                <div className="errorComponent container mt-3 h-100" role="alert">
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        Your session has expired or you are not logged in. Please{' '}
                        <a href={Util.routeHashToURL(conf.hash.login)}>Sign in</a> and try again.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return <LoadingErringComponent error={this.state.httpError} loading={this.state.profile === null} />;
      }
    }

    const profile = this.state.profile;
    const votes = this.state.committeeCycleVotes;
    const allCommitteeCycles: CommitteeCycleModel[] = this.props.committeeCycles;

    const committeeCycleGroups: CommitteeCycleGroups = this.groupCommitteeCycles(votes, allCommitteeCycles);

    return (
      <div id="myStandardsBallotsComponent" className="container mt-3">
        <Switch>
          {allCommitteeCycles.map(
            (x: CommitteeCycleModel, i: number): JSX.Element => (
              <Route
                key={i}
                path={Util.addParamsToRoute(conf.hash.standardsBallotsVoting, [x.committee, x.period])}
                render={() => (
                  <StandardsBallotsFormCardComponent
                    committeeCycleModel={x}
                    voteModels={this.getCommitteeCycleVote(votes, x)}
                    onSave={(v: StandardsBallotsVoteModel[]): Promise<void> => this.saveVote(v)}
                  />
                )}
              />
            )
          )}
          <Route
            path="*"
            render={() => (
              <React.Fragment>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <CardComponent title="Instructions">
                      <div className="mb-3 description-text">
                        <p>
                          Please review documents located on the{' '}
                          <a
                            href={Util.routeHashToURL(conf.hash.standardsBallots)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ballot page
                          </a>
                          .
                        </p>
                      </div>
                    </CardComponent>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <CardComponent title="Ballots">
                      {committeeCycleGroups.unsubmittedCommitteeCycles.map(
                        (committeeCycle: CommitteeCycleModel, i: number): JSX.Element => (
                          <CardFieldRowComponent
                            key={i}
                            title={`${committeeCycle.committee} ${committeeCycle.period}`}
                            subtitle={this.isStandardsBallotRequired(profile, committeeCycle) ? 'REQUIRED' : 'OPTIONAL'}
                            onClick={() =>
                              Util.navToRoute(conf.hash.standardsBallotsVoting, [
                                committeeCycle.committee,
                                committeeCycle.period,
                              ])
                            }
                            includeDivider
                          />
                        )
                      )}
                    </CardComponent>
                  </div>
                  <div className="col-12 col-lg-6">
                    <CardComponent title="Submitted Ballots">
                      {committeeCycleGroups.submittedCommitteeCycles.length > 0 ? (
                        committeeCycleGroups.submittedCommitteeCycles.map(
                          (committeeCycle: CommitteeCycleModel, i: number): JSX.Element => (
                            <CardFieldRowComponent
                              key={i}
                              title={`${committeeCycle.committee} ${committeeCycle.period}`}
                              subtitle="COMPLETED"
                              onClick={() =>
                                Util.navToRoute(conf.hash.standardsBallotsVoting, [
                                  committeeCycle.committee,
                                  committeeCycle.period,
                                ])
                              }
                              includeDivider
                            />
                          )
                        )
                      ) : (
                        <div className="text-center mt-5 mb-5">
                          <i className="description-text">No ballot response submitted.</i>
                        </div>
                      )}
                    </CardComponent>
                  </div>
                </div>
              </React.Fragment>
            )}
          />
        </Switch>
      </div>
    );
  }
}
