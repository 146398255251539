import React from 'react';
import { ProfileModel } from 'services/Models';
import InputComponent from 'components/common/InputComponent';
import Validators from 'util/validators';
import FormComponent from 'components/common/FormComponent';
import SelectComponent from 'components/common/SelectComponent';
import { countries, countriesStates, defaultStates } from 'config';
import { LoggerService } from 'services/LoggerService';
import Util from 'util/util';
import { TFunction } from 'i18next';
import { Translation } from 'react-i18next';

import './AddressFormComponent.scss';

interface FieldErrors {
  billAddressee?: string;
  billAddress1?: string;
  billCity?: string;
  billCountryCode?: string;
  billState?: string;
  billZipcode?: string;
}

const logger = LoggerService.getLogger('AddressFormComponent');

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class AddressFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;
    const c = this.state.editedProfile.customer;
    console.log(c.billCountry);

    const isValidCountryCode = c.billCountryCode ?? '' in countriesStates;
    if (!isValidCountryCode) {
      logger.warn('Tried setting settings for unknown country code (' + c.billCountryCode + ')');
    }

    const stateResults = isValidCountryCode
      ? countriesStates[c.billCountryCode as keyof typeof countriesStates]
      : defaultStates;

    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div id="addressFormComponent">
              <FormComponent
                title={t('Update Address')}
                buttonText={t('Update Address')}
                validate={this.validate}
                onSave={(): Promise<void> => {
                  return this.props.onSave(this.state.editedProfile);
                }}
              >
                <InputComponent
                  label={t('Addressee')}
                  value={c.billAddressee || ''}
                  // error={this.state.fieldErrors.billAddressee}
                  error={t(this.state.fieldErrors.billAddressee || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, billAddressee: e.target.value } } });
                  }}
                />

                <InputComponent
                  label={t('Address Line') + ' 1'}
                  value={c.billAddress1 || ''}
                  // error={this.state.fieldErrors.billAddress1}
                  error={t(this.state.fieldErrors.billAddress1 || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, billAddress1: e.target.value } } });
                  }}
                />

                <InputComponent
                  label={t('Address Line') + ' 2'}
                  value={c.billAddress2 || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, billAddress2: e.target.value } } });
                  }}
                />

                <InputComponent
                  label={t('City')}
                  value={c.billCity || ''}
                  // error={this.state.fieldErrors.billCity}
                  error={t(this.state.fieldErrors.billCity || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, billCity: e.target.value } } });
                  }}
                />

                <SelectComponent
                  label={t('Country / Region')}
                  value={c.billCountryCode || ''}
                  className="form-row pl-5 pr-5"
                  //error={this.state.fieldErrors.billCountryCode}
                  error={t(this.state.fieldErrors.billCountryCode || '')}
                  selectOptions={countries}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    this.setState({
                      editedProfile: {
                        ...p,
                        customer: {
                          ...c,
                          billCountryCode: e.target.value,
                          billCountry: Util.codeToCountry(e.target.value),
                        },
                      },
                    });
                  }}
                />
                {c.billCountry === 'United States' || c.billCountry === 'China' || c.billCountry === 'Japan' ? (
                  <SelectComponent
                    label={t('State / Province')}
                    value={c.billState || ''}
                    className="form-row pl-5 pr-5"
                    selectOptions={stateResults}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                      this.setState({ editedProfile: { ...p, customer: { ...c, billState: e.target.value } } });
                    }}
                  />
                ) : (
                  <InputComponent
                    label={t('State / Province')}
                    value={c.billState || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      this.setState({ editedProfile: { ...p, customer: { ...c, billState: e.target.value } } });
                    }}
                  />
                )}

                <InputComponent
                  label={t('Zip Code')}
                  value={c.billZipcode || ''}
                  // error={this.state.fieldErrors.billZipcode}
                  error={t(this.state.fieldErrors.billZipcode || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, billZipcode: e.target.value } } });
                  }}
                />
              </FormComponent>
            </div>
          );
        }}
      </Translation>
    );
  }

  private validate = (): boolean => {
    const c = this.state.editedProfile.customer;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(c.billAddressee)) {
      fieldErrors.billAddressee = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.billAddress1)) {
      fieldErrors.billAddress1 = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.billCity)) {
      fieldErrors.billCity = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.billZipcode)) {
      fieldErrors.billZipcode = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.billCountry)) {
      fieldErrors.billCountryCode = Validators.requiredError;
    }

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };
}
