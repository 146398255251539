import React from 'react';
import { HttpError } from 'services/HttpService';
import SpinnerComponent from 'components/SpinnerComponent';
import Util from 'util/util';
import { Translation } from 'react-i18next';
import { TFunction } from 'i18next';

import './FormComponent.scss';

type Props = React.PropsWithChildren<{
  onSave: () => Promise<void>;
  validate: () => boolean;
  title: string;
  buttonText: string;
  buttonDanger?: boolean;
}>;
type State = {
  spinnerVisible: boolean;
  errorMessage: string;
};
export default class FormComponent extends React.Component<Props, State> {
  public state: State = {
    spinnerVisible: false,
    errorMessage: '',
  };

  public render(): JSX.Element {
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div className="formComponent">
              <form
                className="container"
                onSubmit={(e): void => {
                  e.preventDefault();
                  this.save();
                }}
              >
                <div className="form-row">
                  <h5 className="text-uppercase text-center w-100 p-2 pb-3">{this.props.title}</h5>
                </div>
                <div className={'mb-4 ' + (this.state.spinnerVisible ? '' : 'd-none')}>
                  <SpinnerComponent visible={this.state.spinnerVisible} />
                </div>

                <div className={'form-fields ' + (this.state.spinnerVisible ? 'd-none' : '')}>
                  {this.props.children}

                  <div className={'form-row ' + (this.state.errorMessage ? 'd-flex' : 'd-none')}>
                    <div className="col-12 pr-0 pl-0">
                      <div className="alert alert-danger" role="alert">
                        {t(this.state.errorMessage)}
                      </div>
                    </div>
                  </div>
                  {this.props.buttonText && (
                    <div className="form-row mt-4 mb-5">
                      <button
                        id={'save-' + Util.removeSpaces(this.props.title)}
                        type="button"
                        className={
                          'btn superRadius float-right pl-5 pr-5 pt-2 pb-2 m-auto text-uppercase text-center ' +
                          (this.props.buttonDanger ? 'btn-danger' : 'btn-primary')
                        }
                        onClick={this.save}
                      >
                        {this.props.buttonText}
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          );
        }}
      </Translation>
    );
  }

  private save = (): void => {
    if (!this.props.validate()) {
      this.setState({ errorMessage: 'Form Error' });
      return;
    }

    this.setState({
      spinnerVisible: true,
      errorMessage: '',
    });

    this.props.onSave().catch((httpError: HttpError): void => {
      this.setState({
        spinnerVisible: false,
        errorMessage: httpError.toString(),
      });
    });
  };
}
