import React from 'react';
import { CustomerModel, ProfileModel } from 'services/Models';
import InputComponent from 'components/common/InputComponent';
import Validators from 'util/validators';
import FormComponent from 'components/common/FormComponent';
import { TFunction } from 'i18next';
import { Translation } from 'react-i18next';

import './ProfileFormComponent.scss';

interface FieldErrors {
  FirstName?: string;
  LastName?: string;
  CompanyName?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class ProfileFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });
  }

  public render(): JSX.Element {
    const p: ProfileModel = this.state.editedProfile;
    const c: CustomerModel = p.customer;

    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div id="profileFormComponent">
              <FormComponent
                title={t('Update Profile')}
                buttonText={t('Update Profile')}
                validate={this.validate}
                onSave={(): Promise<void> => {
                  return this.props.onSave(this.state.editedProfile);
                }}
              >
                <InputComponent
                  label={t('First Name')}
                  value={c.firstname}
                  // error={this.state.fieldErrors.FirstName}
                  error={t(this.state.fieldErrors.FirstName || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, firstname: e.target.value } } });
                  }}
                />

                <InputComponent
                  label={t('Last Name')}
                  value={c.lastname}
                  // error={this.state.fieldErrors.LastName}
                  error={t(this.state.fieldErrors.LastName || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, lastname: e.target.value } } });
                  }}
                />
                {c.language === 'zh_TW' ||
                c.language === 'zh_CN' ||
                c.language === 'ja_JP' ||
                c.language === 'ko_KR' ? (
                  <div>
                    <InputComponent
                      label={t('Local First Name')}
                      value={c.localFirstname || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({ editedProfile: { ...p, customer: { ...c, localFirstname: e.target.value } } });
                      }}
                    />
                    <InputComponent
                      label={t('Local Last Name')}
                      value={c.localLastname || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({ editedProfile: { ...p, customer: { ...c, localLastname: e.target.value } } });
                      }}
                    />{' '}
                  </div>
                ) : (
                  <p></p>
                )}
                {c.language === 'ja_JP' ? (
                  <div>
                    <InputComponent
                      label={t('Phonetic First Name')}
                      value={c.phoneticFirstName || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({
                          editedProfile: { ...p, customer: { ...c, phoneticFirstName: e.target.value } },
                        });
                      }}
                    />

                    <InputComponent
                      label={t('Phonetic Last Name')}
                      value={c.phoneticLastName || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({
                          editedProfile: { ...p, customer: { ...c, phoneticLastName: e.target.value } },
                        });
                      }}
                    />
                  </div>
                ) : (
                  <p></p>
                )}

                <InputComponent
                  label={t('Company')}
                  value={c.companyName}
                  // error={this.state.fieldErrors.companyName}
                  error={t(this.state.fieldErrors.CompanyName || '')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, companyName: e.target.value } } });
                  }}
                />

                <InputComponent
                  label={t('Title')}
                  value={c.title || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    this.setState({ editedProfile: { ...p, customer: { ...c, title: e.target.value } } });
                  }}
                />
                {c.language === 'zh_TW' ||
                c.language === 'zh_CN' ||
                c.language === 'ja_JP' ||
                c.language === 'ko_KR' ? (
                  <InputComponent
                    label={t('Local Title')}
                    value={c.localTitle || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      this.setState({ editedProfile: { ...p, customer: { ...c, localTitle: e.target.value } } });
                    }}
                  />
                ) : (
                  <p></p>
                )}
              </FormComponent>
            </div>
          );
        }}
      </Translation>
    );
  }

  private validate = (): boolean => {
    const c = this.state.editedProfile.customer;
    const fieldErrors: FieldErrors = {};

    if (!Validators.isNotEmpty(c.firstname)) {
      fieldErrors.FirstName = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.lastname)) {
      fieldErrors.LastName = Validators.requiredError;
    }

    if (!Validators.isNotEmpty(c.companyName)) {
      fieldErrors.CompanyName = Validators.requiredError;
    }

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };
}
