import React from 'react';
import { conf } from './config';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import NotFoundComponent from 'components/NotFoundComponent';
import { HashRouter } from 'react-router-dom';
import LoginComponent from 'components/LoginComponent';
import LogoutComponent from 'components/LogoutComponent';
import HeaderComponent from 'components/header/HeaderComponent';
import HomeComponent from 'components/home/HomeComponent';
import AccountComponent from 'components/account/AccountComponent';
import CommitteesComponent from 'components/committees/CommitteesComponent';
import StandardsBallotsComponent from 'components/standardsBallots/StandardsBallotsComponent';
import FooterComponent from 'components/footer/FooterComponent';
import I18nService from 'services/i18n/i18nService';
import CustomerDownloadComponent from 'components/customerDownload/CustomerDownloadComponent';

import './App.scss';

export default class App extends React.Component {
  public state = {};

  constructor(props: any) {
    super(props);
    // Setup translation.
    I18nService.init();
  }

  private static rootRedirect(): JSX.Element {
    return <Redirect to={conf.hash.home} />;
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <HashRouter>
          <Switch>
            {/* <Route path={conf.hash.productDownload} component={HeaderProductDownloadComponent} />
          <Route path={conf.hash.home} component={HeaderProductDownloadComponent} /> */}
            <Route component={HeaderComponent} />
          </Switch>
          <div className="min-vh-100-less-footer">
            <Switch>
              <Route exact path={conf.pathRoot} render={App.rootRedirect} />
              <Route path={conf.hash.home} component={HomeComponent} />
              <Route path={conf.hash.account} component={AccountComponent} />
              <Route path={conf.hash.committees} component={CommitteesComponent} />
              <Route path={conf.hash.login} component={LoginComponent} />
              <Route path={conf.hash.logout} component={LogoutComponent} />
              <Route path={conf.hash.standardsBallots} component={StandardsBallotsComponent} />
              <Route path={conf.hash.productDownload} component={CustomerDownloadComponent} />
              <Route component={NotFoundComponent} />
            </Switch>
          </div>
          <FooterComponent />
        </HashRouter>
      </React.Fragment>
    );
  }
}
