import React from 'react';
import * as H from 'history';
import { conf } from 'config';
import Util from 'util/util';
import TopBarComponent from './TopBarComponent';

import './HeaderComponent.scss';

type Props = {
  // Props history and location are implicitly passed to wrapped components of <Route>
  location: H.Location;
};
type State = {
  mobileOpen: boolean;
};

export default class HeaderComponent extends React.Component<Props, State> {
  public state: State = {
    mobileOpen: false,
  };

  private closeMobileNav = (): void => {
    this.setState({ mobileOpen: false });
  };

  private toggleMobileNav = (): void => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  public render(): JSX.Element {
    const hashRoute = '/' + Util.getPrimaryHashRoute(this.props.location.pathname);

    return (
      <nav id="headerComponent" className="site-header  navbar-expand-md navbar-light container-fluid">
        <div id="topBarWrapper" className="row">
          <div className="col-12">
            <div className="container d-none d-md-block py-1">
              <TopBarComponent hideLogin={false} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <img className="pt-2 d-block d-md-none float-left" height="40" src="./images/logo.svg" alt="Semi" />
            <button
              className="navbar-toggler float-right m-1"
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleMobileNav}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="container">
              <div id="headerNav" className={'collapse navbar-collapse ' + (this.state.mobileOpen ? 'show' : '')}>
                <a className="navbar-brand d-none d-md-block" href={Util.routeHashToURL('')}>
                  <img src="./images/logo.svg" alt="Semi" />
                </a>
                <ul className="navbar-nav ml-auto">
                  <li>
                    <a
                      className={hashRoute === conf.hash.home ? 'active' : ''}
                      onClick={this.closeMobileNav}
                      href={Util.routeHashToURL(conf.hash.home)}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className={hashRoute === conf.hash.account ? 'active' : ''}
                      onClick={this.closeMobileNav}
                      href={Util.routeHashToURL(conf.hash.account)}
                    >
                      Account
                    </a>
                  </li>
                  {/* <li>
                  <a className={hashRoute === conf.hash.committees ? 'active' : ''}
                    onClick={this.closeMobileNav}
                    href={Util.routeHashToURL(conf.hash.committees)}>Communities</a>
                </li> */}
                  <li>
                    <a
                      className={hashRoute === conf.hash.standardsBallots ? 'active' : ''}
                      onClick={this.closeMobileNav}
                      href={Util.routeHashToURL(conf.hash.standardsBallots)}
                    >
                      Standards Ballots
                    </a>
                  </li>
                  <li>
                    <a
                      className={hashRoute === conf.hash.productDownload ? 'active' : ''}
                      onClick={this.closeMobileNav}
                      href={Util.routeHashToURL(conf.hash.productDownload)}
                    >
                      Product Download
                    </a>
                  </li>
                </ul>
                <TopBarComponent extraClasses="d-block d-md-none" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
