import React from 'react';
import Util from 'util/util';

import './CheckboxComponent.scss';

type Props = {
  label: string;
  error?: string;
  id?: string;
  checked: boolean;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
type State = Record<string, never>;
export default class CheckboxComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label);

    return (
      <div className="inputComponent form-row pl-5 pr-5 mb-2">
        <div className="form-group w-100">
          <div className="custom-control custom-checkbox">
            <input
              className={'custom-control-input form-control ' + (this.props.error ? 'is-invalid' : '')}
              id={id}
              name={id}
              type="checkbox"
              checked={this.props.checked}
              readOnly={!!this.props.readOnly}
              onChange={this.props.onChange}
            />
            <label
              className={'custom-control-label text-capitalize pt-1 pl-3 ' + (this.props.error ? 'text-danger' : '')}
              htmlFor={id}
              aria-label={this.props.label}
            >
              {this.props.label}
            </label>
          </div>
          <div className={this.props.error ? '' : 'd-none'}>
            <small className="text-danger">{this.props.error}</small>
          </div>
        </div>
      </div>
    );
  }
}
