import React from 'react';

import './ModalComponent.scss';

type Props = React.PropsWithChildren<{
  onClose: () => void;
  w770?: boolean;
}>;
type State = Record<string, never>;
export default class ModalComponent extends React.Component<Props, State> {
  public state: State = {};
  private modalBackgroundRef = React.createRef<HTMLDivElement>();

  public render(): JSX.Element {
    return (
      <div
        id="modalComponent"
        className={'modal fade d-block show'}
        tabIndex={-1}
        ref={this.modalBackgroundRef}
        role="dialog"
        aria-labelledby="modal-center"
        aria-hidden="true"
        onClick={this.closeFromBackground}
      >
        <div className={'modal-dialog modal-dialog-centered ' + (this.props.w770 ? 'modal-w770' : '')} role="document">
          <div className="modal-content">
            <div className="modal-body container-fluid">
              <button
                type="button"
                className="close position-absolute float-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="row">
                <div className="col-12">{this.props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private closeFromBackground = (event: React.MouseEvent): void => {
    const modalBackgroundEl = this.modalBackgroundRef.current;
    if (this.modalBackgroundRef && event.target === modalBackgroundEl) {
      this.props.onClose();
    }
  };

  private close = (_: React.MouseEvent): void => {
    this.props.onClose();
  };
}
