import React from 'react';
import { Auth0Service } from 'services/Auth0Service';

type Props = Record<string, unknown>;
type State = Record<string, unknown>;

export default class NotFoundComponent extends React.Component<Props, State> {
  public componentDidMount(): void {
    if (window.location.hash.includes('access_token')) {
      Auth0Service.parseAuth0Callback();
      return;
    }
  }

  public render(): JSX.Element {
    return <h4>Page Not Found</h4>;
  }
}
