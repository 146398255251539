import { conf } from 'config';
import { Auth0User } from './Models';
import { HttpError, HttpService } from './HttpService';
import { HTTP_ERROR_CODE, HttpMethod, ProxyRequestBody } from './HttpTypes';
import { Auth0Service } from './Auth0Service';

export type FileField = {
  fieldName: string;
  file: File;
};

export class ProxyService {
  public static proxyRequest(
    proxyUrl: string,
    method: HttpMethod,
    pathParams?: { [key: string]: string | number },
    payload?: any
  ): Promise<any> {
    if (!proxyUrl || !method) {
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing proxy URL or method.'));
    }
    return Auth0Service.getUser()
      .then((auth0User: Auth0User) => {
        console.log(auth0User);
        if (
          !auth0User.idToken ||
          !auth0User.idTokenPayload ||
          !auth0User.idTokenPayload['https://semi.org/ams_id'] ||
          !auth0User.idTokenPayload ||
          !auth0User.idTokenPayload[conf.netSuite.semiEmailKey]
        ) {
          console.log(auth0User.idToken);
          console.log(auth0User.idTokenPayload);
          console.log(auth0User.idTokenPayload['https://semi.org/ams_id']);
          console.log(auth0User.idToken);

          return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing user credentials.'));
        }

        pathParams = pathParams || {};
        payload = payload || null;
        const proxyRequestBody: ProxyRequestBody = {
          method: method,
          pathParams: {
            ...pathParams,
            id: auth0User.idTokenPayload['https://semi.org/ams_id'],
            email: auth0User.idTokenPayload[conf.netSuite.semiEmailKey],
          },
          payload: payload,
        };

        return HttpService.post(proxyUrl, {
          headers: {
            Authorization: auth0User.idToken,
          },
          data: this.applyUniqueFormatting(proxyRequestBody as ProxyRequestBody),
        }).toPromise();
      })
      .catch((err: HttpError) => {
        return Promise.reject(err);
      });
  }

  public static proxyRequestAuth0(
    proxyUrl: string,
    method: HttpMethod,
    pathParams?: { [key: string]: string | number },
    payload?: any
  ): Promise<any> {
    if (!proxyUrl || !method) {
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing proxy URL or method.'));
    }
    console.log(method);
    return Auth0Service.getUser()
      .then((auth0User: Auth0User) => {
        console.log('proxyRequestAuth0');
        if (
          !auth0User.idToken ||
          !auth0User.idTokenPayload ||
          !auth0User.idTokenPayload['https://semi.org/ams_id'] ||
          !auth0User.idTokenPayload ||
          !auth0User.idTokenPayload[conf.netSuite.semiEmailKey]
        ) {
          console.log('if statement 1');
          console.log('3');
          return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing user credentials.'));
        }

        pathParams = pathParams || {};
        payload = payload || null;
        console.log(payload);

        return HttpService.patch(proxyUrl, {
          headers: {
            Authorization: auth0User.idToken,
            'content-type': 'application/json',
          },
          data: payload,
        }).toPromise();
      })
      .catch((err: HttpError) => {
        console.log('catch');
        console.log(err);
        return Promise.reject(err);
      });
  }

  // Returns a new proxy request body after applying formatting specific to certain scripts.
  private static applyUniqueFormatting(originalbody: ProxyRequestBody): ProxyRequestBody {
    const script = originalbody.pathParams.script;
    const newBody: ProxyRequestBody = { ...originalbody };

    if (script === conf.netSuite.standardsBallotsVotesScriptId) {
      const id = originalbody.pathParams.id;
      if (originalbody.method === 'get') {
        delete newBody.pathParams.id;
        newBody.pathParams.masterid = id;
      }
      if (originalbody.method === 'post') {
        const payload = newBody.payload;
        if (payload) {
          newBody.payload = { ...payload, masterId: id };
        }
      }
    }

    return newBody;
  }

  public static proxyUploadFile(proxyUrl: string, payload: File, ballotId: string, lineItemId: string): Promise<any> {
    if (!proxyUrl) {
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing proxy URL.'));
    }

    return new Promise<ProgressEvent<FileReader>>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = resolve;
      reader.readAsDataURL(payload);
    })
      .then((ev: ProgressEvent<FileReader>): Promise<any> => {
        const fileData: string = ev.target ? (ev.target.result as string) : '';

        return Auth0Service.getUser().then((auth0User: Auth0User) => {
          if (
            !auth0User.idToken ||
            !auth0User.idTokenPayload ||
            !auth0User.idTokenPayload['https://semi.org/ams_id'] ||
            !auth0User.idTokenPayload ||
            !auth0User.idTokenPayload[conf.netSuite.semiEmailKey]
          ) {
            console.log('3');
            return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing user credentials.'));
          }

          return HttpService.post(proxyUrl, {
            headers: {
              Authorization: auth0User.idToken,
            },
            data: {
              base64String: fileData,
              userId: auth0User.idTokenPayload['https://semi.org/ams_id'],
              ballotId: ballotId,
              lineItemId: lineItemId,
            },
          }).toPromise();
        });
      })
      .catch((err: HttpError) => {
        return Promise.reject(err);
      });
  }

  public static unauthenticatedProxyRequest(
    proxyUrl: string,
    method: HttpMethod,
    pathParams?: { [key: string]: string | number },
    payload?: any
  ): Promise<any> {
    if (!proxyUrl || !method) {
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, 'Missing proxy URL or method.'));
    }

    pathParams = pathParams || {};
    payload = payload || null;

    const proxyRequestBody: ProxyRequestBody = {
      method: method,
      pathParams: pathParams,
      payload: payload,
    };

    return HttpService.post(proxyUrl, {
      data: proxyRequestBody as ProxyRequestBody,
    }).toPromise();
  }
}
