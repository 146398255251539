import { LoggerService } from 'services/LoggerService';
import { conf, countries } from 'config';

const logger = LoggerService.getLogger('StringUtils');

export default class Util {
  // Like str.replace, but allows multiple values.
  // Example:
  //   replaceAll("foo bar foo", {foo:'a', bar:'b'}) == "a b a"
  public static replaceMap(str: string, mapObj: Record<string, string>): string {
    if (!str || !mapObj) {
      logger.error('Missing required replace map values');
      return str;
    }

    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return str.replace(re, (matched): string => {
      return mapObj[matched];
    });
  }

  public static removeSpaces(str: string): string {
    if (!str || !str.split) {
      return '';
    }

    return str.split(' ').join('');
  }

  public static getPrimaryHashRoute(pathname: string): string {
    return Util.getHashByIndex(pathname, 1);
  }

  public static getHashByIndex(pathname: string, index: number): string {
    if (!pathname || pathname.trim() === '') {
      logger.error('Empty pathname');
      return '';
    }

    const pathSplits = pathname.split('/');
    if (pathSplits.length > index) {
      return pathSplits[index];
    } else {
      return '';
    }
  }

  public static addParamsToRoute(route: string, params: string[]): string {
    return params.reduce((r: string, p: string, i: number): string => {
      return r.replace('{' + i + '}', p);
    }, route);
  }

  public static routeHashToURL(route: string, params?: string[]): string {
    if (!route || !Object.values(conf.hash).includes(route)) {
      route = '';
    } else if (params) {
      route = Util.addParamsToRoute(route, params);
    }

    return window.location.origin + window.location.pathname + conf.hash.root + route;
  }

  public static navToRoute(route: string, params?: string[]): void {
    const url = Util.routeHashToURL(route, params);

    if (url) {
      window.location.assign(url);
    }
  }

  public static stringEllipses(str: string): string {
    if (!str) {
      return '';
    }

    if (str.length > 10) {
      str = str.substr(0, 10) + '...';
    }

    return str;
  }

  public static countryToCode(str: string): string {
    for (const countryCode of countries) {
      if (countryCode.name === str) {
        return countryCode.value;
      }
    }

    return str;
  }

  public static codeToCountry(str: string): string {
    for (const countryCode of countries) {
      if (countryCode.value === str) {
        return countryCode.name;
      }
    }

    return str;
  }
}
