import React from 'react';
import Util from 'util/util';
import { LoggerService } from 'services/LoggerService';
import { Translation } from 'react-i18next';
import { TFunction } from 'i18next';

import './CardComponent.scss';

const logger = LoggerService.getLogger('AccountCardComponent');

type Props = React.PropsWithChildren<{
  title: string;
  editRoute?: string; // Specifies the route to navigate to on button click.
  buttonText?: string; // The text on the button. Defaults to "Update".
  onButtonClick?: () => void; // The function to call onButtonClick. Overrides the edit route.
}>;
type State = Record<string, never>;
export default class CardComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const hasButton = this.props.editRoute || this.props.onButtonClick;
    const buttonId = (this.props.onButtonClick ? 'action-' : 'edit-') + Util.removeSpaces(this.props.title);
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div className="card mb-4 cardComponent">
              <div className="card-body py-3 px-3">
                <div className="container-fluid">
                  <div className="row titleWrap">
                    <div className="col-12">
                      <h5 className="card-title text-uppercase mb-4">{this.props.title}</h5>
                      <div className="card-divider w-100 mb-3" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">{this.props.children}</div>
                  </div>
                  {hasButton && (
                    <div className="row">
                      <div className="col-12">
                        <div className="col-4 px-0 float-right">
                          <button
                            id={buttonId}
                            className="btn btn-primary superRadius float-right pt-2 pb-2 m-auto text-uppercase"
                            onClick={
                              this.props.onButtonClick
                                ? this.props.onButtonClick
                                : (): void => {
                                    if (this.props.editRoute) {
                                      Util.navToRoute(this.props.editRoute);
                                    } else {
                                      logger.error('Missing required prop editRoute.');
                                    }
                                  }
                            }
                          >
                            {this.props.buttonText ? this.props.buttonText : t('Update')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
