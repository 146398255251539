import { conf } from 'config';
import { HTTP_ERROR_CODE, HttpMethod, HttpParams, HttpRequestOptions } from './HttpTypes';
import { catchError, map, retryWhen, switchMap, timeout } from 'rxjs/operators';
import Axios from 'axios-observable';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable, throwError, TimeoutError, timer } from 'rxjs';
import Util from 'util/util';
import { saveAs } from 'file-saver';
import NodeBuffer from 'buffer/';
import { ObservableInput } from 'rxjs/internal/types';
import { LoggerService } from './LoggerService';

const HTTP_RETRY_INTERVAL = conf.httpRetryInterval;
const HTTP_RETRY_LIMIT = conf.httpRetryLimit;
const HTTP_REQUEST_TIMEOUT = conf.httpRequestTimeout;
const HTTP_REQUEST_TIMEOUT_RETRY_LIMIT = conf.httpRequestTimeoutRetryLimit;
const RES_TYPE = 'arraybuffer';

const logger = LoggerService.getLogger('HttpService');

export class HttpError {
  public readonly code: number;
  public readonly message: string;

  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }

  public toString(): string {
    return `${this.message || conf.defaultErrMessage}`;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class HttpUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static parseJson(res: AxiosResponse): { [key: string]: any } | null {
    if (!res || res.data === null || res.data === undefined) {
      return null;
    }

    let responseText = '';
    try {
      responseText = NodeBuffer.Buffer.from(res.data, 'binary').toString();
    } catch (e) {
      logger.error('Failed reading response body from server, returning null.');
      return null;
    }

    const url = HttpUtils.rebuildReqUrl(res);

    if (responseText === '' || responseText === 'OK') {
      logger.info(`${res.status} ${res.statusText}: ${url}`);
      return null;
    }

    try {
      logger.info(`${res.status} ${res.statusText}: ${url}`);
      return JSON.parse(responseText);
    } catch (e) {
      const resTextObj = {
        responseText: responseText,
      };

      logger.warn(`${url}: Server returned text instead of JSON, using: ${JSON.stringify(resTextObj)}`);

      return resTextObj;
    }
  }

  // Axios leaves the query params out of the url, and this method recreates the full url.
  public static rebuildReqUrl(res: AxiosResponse): string {
    let url = res.config.url || '';
    if (res.config.params) {
      const params = res.config.params;
      const paramKeys = Object.keys(params);
      if (paramKeys.length > 0) {
        try {
          // build the query params back in
          url = url + '?' + paramKeys.map((k: string): string => `${k}=${encodeURIComponent(params[k])}`).join('&');
        } catch (e) {
          logger.warn(`${url}: Failed to rebuild url string`);
        }
      }
    }

    return url;
  }

  public static parseFile(res: AxiosResponse): void {
    if (!res || res.data === null || res.data === undefined) {
      logger.warn('Ignoring file download: empty response.');
      return;
    }

    let fileName = res.headers ? res.headers[conf.filenameHeader] : '';
    if (!fileName) {
      fileName = conf.defaultDownloadedFileName;
    }

    try {
      HttpUtils.saveFile(res.data, fileName);
      logger.info(`${res.status} ${res.statusText}: ${res.config.url}: ${fileName}`);
    } catch (e) {
      logger.error('Failed piping file download:', fileName, res.data, (e as Error)?.message ?? String(e));
    }
  }

  // Wrapping es5 import for testability.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static saveFile(data: any, fileName: string): void {
    saveAs(data, fileName);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static parseUrlWithParams(urlPath: string, params: HttpParams | any): string {
    const base = '';
    if (!params || Object.keys(params).length === 0) {
      return base + urlPath;
    }
    const url = base + urlPath;

    const colonParams = {} as Record<string, string>;
    Object.keys(params).forEach((key: string) => {
      colonParams[':' + key] = params[key];
    });

    return Util.replaceMap(url, colonParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static timedRetryHandler(
    errObs: Observable<any>,
    httpRetryLimit: number | any = HTTP_RETRY_LIMIT,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    url: string
  ): Observable<any> {
    if (httpRetryLimit === null || httpRetryLimit === undefined) {
      httpRetryLimit = conf.httpRetryLimit;
    }

    let count = 0;
    return errObs.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((res: AxiosResponse | any): ObservableInput<any> => {
        if (res instanceof TimeoutError && count < HTTP_REQUEST_TIMEOUT_RETRY_LIMIT) {
          logger.debug('Retrying request after timeout to url "' + url + '".', 'Attempt ' + count + '.');

          count += 1;
          return timer(HTTP_RETRY_INTERVAL);
        } else if (
          count >= httpRetryLimit ||
          (res.status as HTTP_ERROR_CODE) === HTTP_ERROR_CODE.BAD_REQUEST ||
          res.status === HTTP_ERROR_CODE.UNAUTHORIZED
        ) {
          return throwError(res);
        } else {
          logger.debug('Retrying request to url "' + url + '".', 'Attempt ' + count + '.');

          count += 1;
          return timer(HTTP_RETRY_INTERVAL);
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static errHandler(res: AxiosResponse | any): Observable<HttpError | any> {
    if (res instanceof TimeoutError) {
      return throwError(new HttpError(408, res.message));
    }

    let code = 0;
    let message = '';
    try {
      code = res.response.status;
      message = res.response.statusText;
    } catch (err) {
      logger.error('Error parsing server error message.');
    }

    const emptyMessage = !message || !message.toLowerCase() || message.toLowerCase().includes('ok');
    if (code === 0) {
      message =
        'Unable to reach server. Please check your network connection or' +
        ' wait for the service to come back online.';
    } else if (emptyMessage || code === HTTP_ERROR_CODE.SYSTEM) {
      message = '';
    }

    logger.error('Failed request to url', res.config.url + '. HTTP Code: ' + code + '. Error message:', message + '.');

    return throwError(new HttpError(code, message));
  }
}

// tslint:disable-next-line:max-classes-per-file
export class HttpService {
  /*
      Create an HTTP GET request to fetch a resource.

      Example usage:
        HttpService.get("/foo/:bar/baz?query=:value", {
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to get")
        })
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static get(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('get', urlPath, requestOptions);
  }
  public static patch(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('patch', urlPath, requestOptions);
  }
  /*
      Create an HTTP POST request to create a new resource.

      Example usage:
        const user = {name: "foo"};

        HttpService.post("/foo/:bar/baz", {
          data: user,
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static post(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('post', urlPath, requestOptions);
  }

  /*
      Create an HTTP PUT request to update a resource.

      Example usage:
        const user = {name: "foo"};

        HttpService.put("/foo/:bar/baz", {
          data: user,
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static put(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('put', urlPath, requestOptions);
  }

  /*
      Create an HTTP DELETE request to remove a resource.

      Example usage:
        HttpService.delete("/foo/:bar/baz", {
          pathParams: {
            bar: "dog"
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */
  public static delete(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService._request('delete', urlPath, requestOptions);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService.get(urlPath, { ...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile });
  }

  public static putFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService.put(urlPath, { ...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile });
  }

  public static postFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService.post(urlPath, { ...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile });
  }

  public static _request(method: HttpMethod, urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    requestOptions = requestOptions || ({} as HttpRequestOptions);

    const { externalUrl, pathParams, queryParams, responseType, data } = requestOptions;

    const url = externalUrl || HttpUtils.parseUrlWithParams(urlPath, pathParams);

    if ((method === 'put' || method === 'post') && !requestOptions.data) {
      logger.warn(`data should be provided in the requestOptions for ${method} ${url}`);
    }

    const axiosOpts: AxiosRequestConfig = {
      method: method,
      url: url,
      data: data || null,
      withCredentials: true,
      responseType: responseType || RES_TYPE,
      params: queryParams || null,
      headers: requestOptions.headers,
    };

    return Axios.request(axiosOpts).pipe(
      timeout(requestOptions.httpTimeout || HTTP_REQUEST_TIMEOUT),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retryWhen((errObs: Observable<any>): Observable<any> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return HttpUtils.timedRetryHandler(errObs, (requestOptions as any).httpRetryLimit, url);
      }),
      catchError(HttpUtils.errHandler),
      map(requestOptions.parser || HttpUtils.parseJson)
    );
  }
}
