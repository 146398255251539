import React from 'react';
import { ProfileService } from 'services/ProfileService';
import { AffiliateModel, CommitteeModel, ProfileModel } from 'services/Models';
import { HttpError } from 'services/HttpService';
import Util from 'util/util';
import { conf } from 'config';
import LoadingErringComponent from 'components/common/LoadingErringComponent';
import CardComponent from 'components/common/CardComponent';
import { Route, Switch } from 'react-router';
import ModalComponent from 'components/modal/ModalComponent';
import { Check } from 'react-feather';
import TechCommitteesFormComponent from 'components/committees/committeesForms/TechCommitteesFormComponent';
import CardFieldRowComponent from 'components/common/CardFieldRowComponent';
import AffiliateFormComponent from 'components/committees/committeesForms/AffiliateFormComponent';
import AddAffiliateFormComponent from 'components/committees/committeesForms/AddAffiliateFormComponent';
import { HTTP_ERROR_CODE } from 'services/HttpTypes';
import { LoggerService } from 'services/LoggerService';
import { Translation } from 'react-i18next';
import { TFunction } from 'i18next';
import CommitteesEditStandardsComponent from './CommitteesEditStandardsComponent';

import './CommitteesComponent.scss';

const logger = LoggerService.getLogger('CommitteesComponent');

type Props = Record<string, unknown>;
type State = {
  profile: ProfileModel | null;
  httpError: string;
};
export default class CommitteesComponent extends React.Component<Props, State> {
  public state: State = {
    profile: null,
    httpError: '',
  };

  public componentDidMount(): void {
    this.fetchProfile();
  }

  public render(): JSX.Element {
    if (this.state.httpError || this.state.profile === null) {
      console.log(this.state.httpError);
      if (this.state.httpError === 'Not Login') {
        return (
          <React.Fragment>
            <div className="emptySpacer pt-5">
              <div id="accountComponent" className="container mt-3">
                <div className="errorComponent container mt-3 h-100" role="alert">
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        {' '}
                        Your session has expired or you are not logged in. Please{' '}
                        <a href={Util.routeHashToURL(conf.hash.login)}>Sign in</a> and try again.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return <LoadingErringComponent error={this.state.httpError} loading={this.state.profile === null} />;
      }
    }

    const p = this.state.profile;
    const a = p.affiliate;
    const newStandardsPoliciesDate = new Date('06/27/2022');
    const affiliateChosen = a && a.affiliateid;
    const hasStandardsCommittees =
      p.committee.standards.filter(
        (comm) => comm.committeeid !== conf.programCommitteeId && comm.status === conf.active
      ).length > 0;
    // const hasTechCommittees = p.committee.technology.filter((comm) => comm.status === conf.active).length > 0;
    const programComActive = ProfileService.hasActiveProgramCommittee(p.committee.standards);
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <React.Fragment>
              <Switch>
                <Route
                  path={conf.hash.committeesEditStandards}
                  render={() => (
                    <CommitteesEditStandardsComponent
                      profile={p}
                      newStandardsPoliciesDate={newStandardsPoliciesDate}
                      onCloseEdits={this.closeEdits}
                      onSaveCommittees={this.saveCommittees}
                      onSaveCommittees2={this.saveCommittees2}
                    />
                  )}
                />

                <Route
                  path={conf.hash.committeesEditTech}
                  render={() => (
                    <ModalComponent onClose={this.closeEdits}>
                      <TechCommitteesFormComponent
                        onSave={(profile: ProfileModel): Promise<void> => {
                          return this.saveCommittees(profile.committee.technology);
                        }}
                        profileModel={p}
                      />
                    </ModalComponent>
                  )}
                />

                <Route
                  path={conf.hash.committeesEditAffiliate}
                  render={() => (
                    <ModalComponent onClose={this.closeEdits}>
                      <AffiliateFormComponent onSave={this.saveAffiliate} profileModel={p} />
                    </ModalComponent>
                  )}
                />

                <Route
                  path={conf.hash.committeesAddAffiliate}
                  render={() => (
                    <ModalComponent onClose={this.closeAddAffiliate}>
                      <AddAffiliateFormComponent onSave={this.addAffiliate} />
                    </ModalComponent>
                  )}
                />
              </Switch>

              <div id="committeesComponent" className="container mt-3">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <CardComponent
                      title={t('Standards Committees')}
                      editRoute={
                        affiliateChosen ? conf.hash.committeesEditStandards : conf.hash.committeesEditAffiliate
                      }
                    >
                      {affiliateChosen ? (
                        <React.Fragment>
                          <CardFieldRowComponent
                            title={t('Affiliate Company')}
                            value={p.affiliate.affiliateName}
                            includeDivider
                          >
                            <p className="description text-muted">
                              Please contact&nbsp;
                              <a
                                href="https://www.semi.org/en/semi-standards-staff-contacts"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                SEMI Standards
                              </a>
                              &nbsp;to change your voting affiliate.
                            </p>
                            <p className="description text-muted">
                              Please click{' '}
                              <a
                                href="http://downloads.semi.org/web/wstdsbal.nsf/StdCharters"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                HERE
                              </a>{' '}
                              for the charter and scope of all Global Technical Committees.
                            </p>
                          </CardFieldRowComponent>
                          {programComActive && (
                            <div className="row">
                              <div className="col-9 text-capitalize">
                                <p>
                                  <strong>Program Membership</strong>
                                </p>
                              </div>
                              <div className="col-3">
                                <div className="float-right">
                                  <Check />
                                </div>
                              </div>
                            </div>
                          )}

                          {hasStandardsCommittees ? (
                            p.committee.standards.map((comm, i): JSX.Element | null => {
                              if (comm.committeeid === conf.programCommitteeId || comm.status !== conf.active) {
                                return null;
                              }

                              return (
                                <div key={i} className="row">
                                  <div className="col-9 text-capitalize">
                                    <p>
                                      <strong>{comm.committeename}</strong>
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <div className="float-right">
                                      <Check />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="row">
                              <div className={'col-12'}>
                                <p>No selected committees.</p>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ) : (
                        <div className="row">
                          <div className="col-12 ">
                            {/* <p>Please choose an affiliate company to get started.</p> */}
                            <p>{t('Affiliate Message')}</p>
                          </div>
                        </div>
                      )}
                    </CardComponent>
                  </div>

                  <div className="col-12 col-lg-6">
                    {/* <CardComponent title="Tech Committees"
                             editRoute={conf.hash.committeesEditTech}>

                {
                  hasTechCommittees ?
                      p.committee.technology.map((comm, i): JSX.Element | null => {
                        if (comm.status !== conf.active) {
                          return null;
                        }

                        return <div key={i} className="row">
                          <div className="col-9 text-capitalize">
                            <p><strong>{comm.committeename}</strong></p>
                          </div>
                          <div className="col-3">
                            <div className="float-right">
                              <Check/>
                            </div>
                          </div>
                        </div>;
                      }) :
                      <p>No selected tech committees.</p>
                }
              </CardComponent> */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }

  private fetchProfile = (): void => {
    this.setState({ profile: null, httpError: '' });

    ProfileService.getCustomer()
      .then((profile: ProfileModel): void => {
        this.setState({
          profile: profile,
        });
      })
      .catch((httpError: HttpError): void => {
        if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
          //this.setState({httpError: conf.authErrMessage});
          this.setState({ httpError: 'Not Login' });
        } else {
          this.setState({ httpError: httpError.toString() });
        }
      });
  };

  private saveAffiliate = (profileModel: ProfileModel): Promise<void> => {
    return ProfileService.updateAffiliate(
      profileModel.affiliate.affiliateid || '',
      profileModel.customer.masterID || ''
    )
      .then((): void => {
        this.closeEdits();
      })
      .catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  private saveCommittees = (committees: CommitteeModel[]): Promise<void> => {
    if (!this.state.profile) {
      logger.error('Missing profile when trying to update committees.');
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, conf.defaultErrMessage));
    }

    for (const comm of committees) {
      comm.firstname = this.state.profile.customer.firstname;
      comm.lastname = this.state.profile.customer.lastname;
      comm.email = this.state.profile.customer.email;
    }

    return ProfileService.updateCommittees(committees)
      .then((): void => {
        this.closeEdits();
      })
      .catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  private saveCommittees2 = (committees: CommitteeModel[], memberAgree: boolean): Promise<void> => {
    if (!this.state.profile) {
      logger.error('Missing profile when trying to update committees.');
      return Promise.reject(new HttpError(HTTP_ERROR_CODE.BAD_REQUEST, conf.defaultErrMessage));
    }
    for (const comm of committees) {
      comm.firstname = this.state.profile.customer.firstname;
      comm.lastname = this.state.profile.customer.lastname;
      comm.email = this.state.profile.customer.email;
    }
    return ProfileService.updateCommitteesAgree(committees, memberAgree)
      .then((): void => {
        this.closeEdits();
      })
      .catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  private closeEdits = (): void => {
    Util.navToRoute(conf.hash.committees);
    this.fetchProfile();
  };

  private addAffiliate = (affiliate: AffiliateModel): Promise<void> => {
    return ProfileService.addAffiliate(affiliate.affiliateName)
      .then((): void => {
        this.closeAddAffiliate();
      })
      .catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  private closeAddAffiliate = (): void => {
    Util.navToRoute(conf.hash.committeesEditAffiliate);
  };
}
