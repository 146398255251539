import { conf, semiProxyBaseUrl } from 'config';
import { InputListValue } from 'components/common/AutoCompleteComponent';
import Util from 'util/util';
import {
  AffiliateModel,
  AffiliateResponse,
  AffiliateUpdateRequest,
  CommitteeModel,
  CommitteesUpdateRequest,
  CompanyModel,
  CompanyResponse,
  PasswordModel,
  ProfileModel,
  StandardsPoliciesUpdateRequest,
} from './Models';
import i18nService from './i18n/i18nService';
import { ProxyService } from './ProxyService';

export class ProfileService {
  public static getCustomer(): Promise<ProfileModel> {
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'get',
      {
        script: conf.netSuite.customerScriptId,
        recordtype: conf.netSuite.customerRecordType,
      },
      null
    ).then((profile: ProfileModel): Promise<ProfileModel> => {
      i18nService.setLanguage(profile.customer.language);
      return Promise.resolve(profile);
    });
  }

  public static updateCustomer(profileModel: ProfileModel): Promise<void> {
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'put',
      {
        script: conf.netSuite.customerScriptId,
        recordtype: conf.netSuite.customerRecordType,
      },
      profileModel
    );
  }

  public static deleteCustomer(): Promise<void> {
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'delete',
      {
        script: conf.netSuite.customerScriptId,
        recordtype: conf.netSuite.customerRecordType,
      },
      null
    );
  }

  public static updateAffiliate(affiliateid: string, masterId: string): Promise<void> {
    const affiliateUpdate: AffiliateUpdateRequest = {
      affiliateid: affiliateid,
      masterID: masterId,
    };

    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'put',
      {
        script: conf.netSuite.affiliatesScriptId,
      },
      affiliateUpdate
    );
  }

  public static updateCommittees(committees: CommitteeModel[]): Promise<void> {
    const committeesUpdate: CommitteesUpdateRequest = {
      data: committees,
    };
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'put',
      {
        script: conf.netSuite.committeesUpdateScriptId,
      },
      committeesUpdate
    );
  }

  public static updateCommitteesAgree(committees: CommitteeModel[], memberAgree: boolean): Promise<void> {
    const committeesUpdate: StandardsPoliciesUpdateRequest = {
      memberAgree: memberAgree,
      standardsPoliciesDate: new Date(),
      data: committees,
    };
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'put',
      {
        script: conf.netSuite.committeesUpdateScriptId,
      },
      committeesUpdate
    );
  }
  public static updatePassword(passwordModel: PasswordModel, auth0Id: string): Promise<void> {
    console.log(auth0Id);
    const newPassword = passwordModel.password;
    const parameter = { password: newPassword, connection: 'SEMI' };
    const urlString = 'https://semi-org.auth0.com/api/v2/users/' + auth0Id;
    return ProxyService.proxyRequestAuth0(urlString, 'patch', {}, parameter);
    // var axios = require("axios").default;
    //
    //
    // var options = {
    //   method: 'PATCH',
    //   url: urlString,
    //   headers: {'content-type': 'application/json'},
    //   data: {password: newPassword, connection: 'SEMI'}
    // };

    // axios.request(options).then(function (response: { data: any; }) {
    //   console.log(response.data);
    // }).catch(function (error: any) {
    //   console.error(error);
    // });
    // return  Promise.resolve();
  }

  public static getCompanies(): Promise<CompanyResponse> {
    return ProxyService.proxyRequest(semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite, 'get', {
      script: conf.netSuite.companiesScriptId,
    });
  }

  public static getAffiliates(): Promise<AffiliateResponse> {
    return ProxyService.proxyRequest(semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite, 'get', {
      script: conf.netSuite.affiliatesScriptId,
    });
  }

  public static addAffiliate(affiliateName: string): Promise<AffiliateModel> {
    const aff: AffiliateModel = {
      affiliateName: affiliateName,
    };

    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'post',
      {
        script: conf.netSuite.affiliatesScriptId,
      },
      aff
    );
  }

  public static findProgramCommittee(comms: CommitteeModel[]): CommitteeModel | null {
    for (const comm of comms) {
      if (comm.committeeid === conf.programCommitteeId) {
        return comm;
      }
    }

    return null;
  }

  public static hasProgramCommittee(comms: CommitteeModel[]): boolean {
    return ProfileService.findProgramCommittee(comms) !== null;
  }

  public static hasActiveProgramCommittee(comms: CommitteeModel[]): boolean {
    const comm = ProfileService.findProgramCommittee(comms);

    return comm !== null && comm.status === conf.active;
  }

  public static companiesToInputValues(companies: CompanyModel[]): Array<InputListValue<CompanyModel>> {
    if (!companies || companies.length < 1) {
      return [];
    }

    return companies.map((company): InputListValue<CompanyModel> => {
      return { id: company.memberCompanyID + '', value: company.memberCompanyName, data: company };
    });
  }

  public static affiliatesToInputValues(affiliateResponse: AffiliateResponse): Array<InputListValue<AffiliateModel>> {
    const affiliates: AffiliateModel[] = [];
    for (const key in affiliateResponse) {
      if (affiliateResponse.hasOwnProperty(key)) {
        affiliates.push(affiliateResponse[key]);
      }
    }

    return affiliates.map((affiliate): InputListValue<AffiliateModel> => {
      const name = Util.stringEllipses(affiliate.affiliateName);
      const variant = affiliate.affiliateVariant;

      return {
        id: affiliate.affiliateid + '',
        value: name + ' ' + (variant ? '(' + variant + ')' : ''),
        data: affiliate,
      };
    });
  }

  public static committeesToInputValues(committees: CommitteeModel[]): Array<InputListValue<CommitteeModel>> {
    if (!committees || committees.length < 1) {
      return [];
    }

    return committees.map((committee: CommitteeModel): InputListValue<CommitteeModel> => {
      return { id: committee.committeeid, value: committee.committeename, data: committee };
    });
  }
}
