import React from 'react';
import { StandardsBallotsVoteModel, CommitteeCycleModel, StandardsBallotLineItemModel } from 'services/Models';
import RadioButtonComponent, { RadioButton } from 'components/common/RadioButtonComponent';
import CardComponent from 'components/common/CardComponent';
import CardFieldRowComponent from 'components/common/CardFieldRowComponent';
import TextAreaComponent from 'components/common/TextAreaComponent';
import { HttpError } from 'services/HttpService';
import Validators from 'util/validators';
import FileComponent from 'components/common/FileComponent';
import { BallotsService } from 'services/BallotsService';
import SpinnerComponent from 'components/SpinnerComponent';
import { BallotTypes, technicalOptions, ratificationOptions, VotingOptions } from 'services/BallotTypes';
import Util from 'util/util';
import { conf } from 'config';
import { Translation } from 'react-i18next';
import { TFunction } from 'i18next';

import './StandardsBallotsFormCardComponent.scss';

interface FieldErrors {
  voteSelection?: string;
  comments?: string;
}

type Props = {
  committeeCycleModel: CommitteeCycleModel;
  voteModels: StandardsBallotsVoteModel[];
  onSave: (voteModels: StandardsBallotsVoteModel[]) => Promise<void>;
};
type State = {
  editedVotes: StandardsBallotsVoteModel[];
  fieldErrors: FieldErrors[];
  spinnerVisible: boolean;
  errorMessage: string;
};

export default class StandardsBallotsFormCardComponent extends React.Component<Props, State> {
  public state: State = {
    editedVotes: this.props.voteModels,
    fieldErrors: Array(this.props.voteModels.length).fill({}),
    spinnerVisible: false,
    errorMessage: '',
  };

  private getDocumentTitle = (lineItem: StandardsBallotLineItemModel): string => {
    // Append "(Ratification ballot)" for ratification ballots

    return lineItem.ballotTypeId === BallotTypes.Ratification
      ? lineItem.docTitle + ' (Ratification ballot)'
      : lineItem.docTitle;
  };

  private getRadioButtonOptions = (ballotTypeId: BallotTypes): RadioButton[] => {
    return (ballotTypeId === BallotTypes.Ratification ? ratificationOptions : technicalOptions).map(
      (option: string): RadioButton => {
        return { label: option, value: option };
      }
    );
  };

  private areCommentsDisabled = (vote: StandardsBallotsVoteModel): boolean => {
    if (vote.ballotTypeId === BallotTypes.Technical && vote.vote) {
      return vote.vote === VotingOptions.Accept || vote.vote === VotingOptions.Abstain;
    }
    if (vote.ballotTypeId === BallotTypes.Ratification && vote.vote) {
      return vote.vote !== VotingOptions.Disapprove;
    }
    return true;
  };

  private updateVote = (lineItem: StandardsBallotLineItemModel, newVote: StandardsBallotsVoteModel): void => {
    this.setState({
      editedVotes: this.state.editedVotes.map((v: StandardsBallotsVoteModel): StandardsBallotsVoteModel => {
        return lineItem.ballotInternalId === v.ballotInternalId && lineItem.lineItemInternalId === v.lineItemInternalId
          ? newVote
          : v;
      }),
    });
  };

  private onFileUpload = (lineItem: StandardsBallotLineItemModel, e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;

    if (files) {
      this.setState({
        editedVotes: this.state.editedVotes.map((v: StandardsBallotsVoteModel): StandardsBallotsVoteModel => {
          return lineItem.ballotInternalId === v.ballotInternalId &&
            lineItem.lineItemInternalId === v.lineItemInternalId
            ? { ...v, uploadedFile: files[0] }
            : v;
        }),
      });
    }
  };

  private validate(): boolean {
    const votes = this.state.editedVotes;
    const fieldErrors: FieldErrors[] = Array.from(votes, () => ({}));

    votes.forEach((v: StandardsBallotsVoteModel, i: number): void => {
      if (!Validators.isNotEmpty(v.vote)) {
        fieldErrors[i].voteSelection = Validators.requiredError;
      }

      if (!this.areCommentsDisabled(v) && !(Validators.isNotEmpty(v.comment) || v.uploadedFile || v.fileName)) {
        fieldErrors[i].comments = Validators.standardsBallotsCommentsError;
      }
    });

    this.setState({ fieldErrors: fieldErrors });

    return !fieldErrors.some((errors: FieldErrors): boolean => Object.keys(errors).length !== 0);
  }

  private save = (): void => {
    if (!this.validate()) {
      this.setState({ errorMessage: Validators.formError });
      return;
    }

    this.setState({
      spinnerVisible: true,
      errorMessage: '',
    });

    this.props.onSave(this.state.editedVotes).catch((httpError: HttpError): void => {
      this.setState({
        spinnerVisible: false,
        errorMessage: httpError.toString(),
      });
    });
  };

  public render(): JSX.Element {
    const c = this.props.committeeCycleModel;
    const ballotLineItems = this.props.committeeCycleModel.ballotLineData;
    const votes = this.state.editedVotes;
    return (
      <Translation>
        {(t: TFunction): JSX.Element => {
          return (
            <div id="standardsBallotsFormCardComponent" className="container">
              <CardComponent
                title={'Ballot Page For ' + c.committee + ' ' + c.period}
                buttonText="Submit Vote"
                onButtonClick={this.state.spinnerVisible ? undefined : this.save}
              >
                <div className="mb-3 description-text">
                  <p>
                    To review documents click{' '}
                    <a href={Util.routeHashToURL(conf.hash.standardsBallots)} target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                    .<br />
                    <i>
                      (Clicking on the link above will open the ballot page in a new tab or a new window depending on
                      your browser settings.)
                    </i>
                  </p>
                  <div className="card-divider w-100 mb-3" />
                </div>
                {this.state.spinnerVisible ? (
                  <div className="mb-4">
                    <SpinnerComponent visible={this.state.spinnerVisible} />
                  </div>
                ) : (
                  ballotLineItems.map((item: StandardsBallotLineItemModel, index: number): JSX.Element => {
                    const v: StandardsBallotsVoteModel = BallotsService.findLineItemVote(item, votes);
                    console.log(v);
                    return (
                      <div key={index} className="row mt-1 mb-1">
                        <div className="col-md-10">
                          <CardFieldRowComponent title={'#' + item.docNum} />

                          <CardFieldRowComponent
                            subtitle={this.getDocumentTitle(item)}
                            valueJSX={
                              <div className="line-items mb-4">
                                <div className="mb-2">{item.lineItem}</div>
                              </div>
                            }
                          />

                          <div className="card-divider-dashed w-100 mb-3" />

                          <div className="d-lg-none">
                            <RadioButtonComponent
                              id={'standardsballot-radiobutton-sm-' + index}
                              radioButtons={this.getRadioButtonOptions(item.ballotTypeId)}
                              value={v.vote ? v.vote : ''}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                this.updateVote(item, { ...v, vote: event.target.value })
                              }
                              // error={this.state.fieldErrors[index].voteSelection}
                              error={t(this.state.fieldErrors[index].voteSelection || '')}
                            />
                          </div>

                          <TextAreaComponent
                            id={'standardsballot-comments-' + index}
                            label="Comments and/or Negatives:"
                            value={v.comment ? v.comment : ''}
                            placeholder="Enter Comments and/or Negatives here."
                            rows={3}
                            disabled={this.areCommentsDisabled(v)}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
                              this.updateVote(item, { ...v, comment: event.target.value });
                            }}
                            error={this.areCommentsDisabled(v) ? '' : this.state.fieldErrors[index].comments}
                          />

                          {v.fileName && (
                            <CardFieldRowComponent
                              valueJSX={
                                <div>
                                  Previously uploaded: <i>{v.fileName}</i>
                                </div>
                              }
                            />
                          )}

                          <FileComponent
                            id={'standardsballot-fileupload-' + index}
                            label={v.fileName ? v.fileName : ''}
                            disabled={this.areCommentsDisabled(v)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => this.onFileUpload(item, e)}
                            multiple={false}
                          />
                        </div>

                        <div className="col-md-2 d-none d-lg-block">
                          <RadioButtonComponent
                            id={'standardsballot-radiobutton-lg-' + index}
                            radioButtons={this.getRadioButtonOptions(item.ballotTypeId)}
                            value={v.vote ? v.vote : ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                              this.updateVote(item, { ...v, vote: event.target.value })
                            }
                            error={this.state.fieldErrors[index].voteSelection}
                          />
                        </div>
                      </div>
                    );
                  })
                )}

                <div className={'form-row ' + (this.state.errorMessage ? 'd-flex' : 'd-none')}>
                  <div className="col-12 pr-0 pl-0">
                    <div className="alert alert-danger" role="alert">
                      {t(this.state.errorMessage)}
                    </div>
                  </div>
                </div>
              </CardComponent>
            </div>
          );
        }}
      </Translation>
    );
  }
}
