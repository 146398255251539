import React from 'react';
import { CommitteeModel, ProfileModel } from 'services/Models';
import FormComponent from 'components/common/FormComponent';
import CheckboxComponent from 'components/common/CheckboxComponent';
import AutoCompleteComponent, { InputListValue } from 'components/common/AutoCompleteComponent';
import { conf } from 'config';
import { ProfileService } from 'services/ProfileService';
import { LoggerService } from 'services/LoggerService';

import './TechCommitteesFormComponent.scss';

const logger = LoggerService.getLogger('TechCommitteesFormComponent');

interface FieldErrors {
  committee?: string;
}

type Props = {
  profileModel: ProfileModel;
  onSave: (profileModel: ProfileModel) => Promise<void>;
};
type State = {
  editedProfile: ProfileModel;
  fieldErrors: FieldErrors;
};
export default class TechCommitteesFormComponent extends React.Component<Props, State> {
  public state: State = {
    editedProfile: this.props.profileModel,
    fieldErrors: {},
  };

  public componentDidMount(): void {
    this.setState({ editedProfile: this.props.profileModel });
  }

  public render(): JSX.Element {
    const p = this.state.editedProfile;

    const searchAbleCommittees = ProfileService.committeesToInputValues(
      p.committee.technology.filter((comm: CommitteeModel): boolean => {
        const active = comm.status === conf.active;

        if (p.customer.ismember) {
          return !active;
        }

        return !active && !comm.reqmembership;
      })
    );

    return (
      <div id="techCommitteesFormComponent">
        <FormComponent
          title={'Update Tech Committees'}
          buttonText="Update Tech Committees"
          validate={this.validate}
          onSave={(): Promise<void> => {
            return this.props.onSave(this.state.editedProfile);
          }}
        >
          {searchAbleCommittees.length > 0 ? (
            <AutoCompleteComponent
              label="Add a Tech Committee"
              clearOnSelected={true}
              listValues={searchAbleCommittees}
              error={this.state.fieldErrors.committee}
              onSelected={this.selectCommittee}
            />
          ) : (
            <div className="autoCompleteComponent form-row pl-5 pr-5 mb-2">
              <div className="form-group w-100">
                <p>There are currently no committees to choose from.</p>
              </div>
            </div>
          )}

          {p.committee.technology.map((comm: CommitteeModel, i): JSX.Element | null => {
            if (comm.status !== conf.active) {
              return null;
            }

            return (
              <div key={i} className="row">
                <div className="col-9 text-capitalize">
                  <p>
                    <strong>{comm.committeename}</strong>
                  </p>
                </div>
                <div className="col-3">
                  <CheckboxComponent
                    id={'edit-comm' + i}
                    checked={true}
                    label={''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      p.committee.technology[i].status = e.target.checked ? conf.active : conf.inactive;
                      this.setState({ editedProfile: { ...p } });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </FormComponent>
      </div>
    );
  }

  private validate = (): boolean => {
    const fieldErrors: FieldErrors = {};

    this.setState({ fieldErrors: fieldErrors });

    return Object.keys(fieldErrors).length === 0;
  };

  private selectCommittee = (listValue: InputListValue<CommitteeModel>): void => {
    if (!listValue.data) {
      logger.error('Missing committee in autocomplete result.');
      return;
    }

    const comms = this.state.editedProfile.committee.technology;

    for (const comm of comms) {
      if (comm.committeeid === listValue.data.committeeid) {
        comm.status = conf.active;
        break;
      }
    }

    this.setState({
      editedProfile: {
        ...this.state.editedProfile,
        committee: {
          ...this.state.editedProfile.committee,
          technology: comms,
        },
      },
    });
  };
}
