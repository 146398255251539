import React from 'react';
import Util from 'util/util';

import './AutoCompleteComponent.scss';

export interface InputListValue<T> {
  id: string;
  value: string;
  data: T | null;
}

type Props = {
  label: string;
  placeholder?: string;
  error?: string;
  id?: string;
  initialSelectedValue?: InputListValue<any>;
  listValues: Array<InputListValue<any>>;
  onSelected: (selectedValue: InputListValue<any>) => void;
  clearOnSelected?: boolean;
};
type State = {
  selectedValue: InputListValue<any>;
};
export default class AutoCompleteComponent extends React.Component<Props, State> {
  public state: State = {
    selectedValue: {
      id: '',
      value: '',
      data: null,
    },
  };

  public componentDidMount(): void {
    if (this.props.initialSelectedValue) {
      this.setState({ selectedValue: this.props.initialSelectedValue });
    }
  }

  public render(): JSX.Element {
    const id = this.props.id || 'edit-' + Util.removeSpaces(this.props.label);
    const listId = id + '-list';

    return (
      <div className="autoCompleteComponent form-row pl-5 pr-5 mb-2">
        <div className="form-group w-100">
          <label htmlFor={id} className={this.props.error ? 'text-danger' : ''}>
            {this.props.label}
          </label>
          <input
            id={id}
            list={listId}
            name={id}
            placeholder={this.props.placeholder || this.props.label}
            type={'text'}
            className={'form-control w-100 pl-3 pr-3 ' + (this.props.error ? 'is-invalid' : '')}
            onChange={this.onChange}
            value={this.state.selectedValue.value}
            onFocus={(): void => {
              this.setState({ selectedValue: { id: '', value: '', data: null } });
            }}
          />

          <datalist id={listId}>
            {this.props.listValues.map((listOption: InputListValue<any>, i: number): JSX.Element => {
              return <option key={i}>{listOption.value}</option>;
            })}
          </datalist>
          <div className={this.props.error ? '' : 'd-none'}>
            <small className="text-danger">{this.props.error}</small>
          </div>
        </div>
      </div>
    );
  }

  private findListValue = (searchValue: string): InputListValue<any> | null => {
    for (const listValue of this.props.listValues) {
      if (searchValue === listValue.value) {
        return listValue;
      }
    }

    return null;
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchStr: string = event.target.value;
    if (searchStr) {
      const listValue = this.findListValue(searchStr);
      if (listValue) {
        if (this.props.clearOnSelected) {
          this.setState({ selectedValue: { id: '', value: '', data: null } });
        } else {
          this.setState({ selectedValue: listValue });
        }

        this.props.onSelected(listValue);
      } else {
        this.setState({ selectedValue: { id: '', value: searchStr, data: null } });
      }
    } else {
      this.setState({ selectedValue: { id: '', value: '', data: null } });
    }
  };
}
