import React from 'react';
import { Auth0Service } from 'services/Auth0Service';
import SpinnerComponent from 'components/SpinnerComponent';

type Props = Record<string, unknown>;
type State = Record<string, unknown>;

export default class LoginComponent extends React.Component<Props, State> {
  public state = {};

  public componentDidMount(): void {
    Auth0Service.login();
  }

  public render(): JSX.Element {
    return <SpinnerComponent visible={true} />;
  }
}
