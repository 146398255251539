import React from 'react';
import ModalComponent from 'components/modal/ModalComponent';
import StandardsPrivacyPolicyFormComponent from 'components/committees/committeesForms/StandardsPrivacyPolicyFormComponent';
import StandardsCommitteesFormComponent from 'components/committees/committeesForms/StandardsCommitteesFormComponent';
import { ProfileModel } from 'services/Models';

type Props = {
  profile: ProfileModel;
  newStandardsPoliciesDate: Date;
  onCloseEdits: () => void;
  onSaveCommittees: (committees: ProfileModel['committee']['standards']) => Promise<void>;
  onSaveCommittees2: (committees: ProfileModel['committee']['standards'], memberAgree: boolean) => Promise<void>;
};

const CommitteesEditStandardsComponent: React.FC<Props> = ({
  profile,
  newStandardsPoliciesDate,
  onCloseEdits,
  onSaveCommittees,
  onSaveCommittees2,
}): JSX.Element => {
  const memberAgreementDate = profile.committee.memberAgreeDate
    ? new Date(profile.committee.memberAgreeDate)
    : new Date('01/01/1990');
  const noDate = profile.committee.memberAgreeDate === '';

  if (!profile.committee.memberAgree || memberAgreementDate < newStandardsPoliciesDate || noDate) {
    return (
      <ModalComponent onClose={onCloseEdits} w770={true}>
        <StandardsPrivacyPolicyFormComponent
          onSave={(updatedProfile: ProfileModel) =>
            onSaveCommittees2(updatedProfile.committee.standards, updatedProfile.committee.memberAgree)
          }
          profileModel={profile}
        />
      </ModalComponent>
    );
  }

  return (
    <ModalComponent onClose={onCloseEdits} w770={true}>
      <StandardsCommitteesFormComponent
        onSave={(updatedProfile: ProfileModel) => onSaveCommittees(updatedProfile.committee.standards)}
        profileModel={profile}
      />
    </ModalComponent>
  );
};

export default CommitteesEditStandardsComponent;
