import React from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Paper, TableContainer } from '@mui/material';
import { CustomerDownloadModel } from 'services/Models';

import './TableComponent.scss';

type Props = {
  data?: string;
  value: CustomerDownloadModel[];
};
type State = object;
export default class TextAreaComponent extends React.Component<Props, State> {
  public render(): JSX.Element {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Document Name</b>
              </TableCell>
              <TableCell align="right">
                <b>Publish Date</b>
              </TableCell>
              <TableCell align="right">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.value.map((row) => (
              <TableRow key={row.downloadId}>
                <TableCell component="th" scope="row">
                  {row.docName}
                </TableCell>
                <TableCell align="right">{row.publishDate}</TableCell>
                <TableCell align="right">
                  <a className="btn active btn-outline-success" href={row.downloadLink}>
                    Download
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
