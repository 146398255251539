import React from 'react';

import './CardFieldRowComponent.scss';

type Props = React.PropsWithChildren<{
  title?: string;
  onClick?: () => void;
  subtitle?: string;
  value?: string;
  valueJSX?: JSX.Element;
  valueNoCaps?: boolean;
  description?: string;
  includeDivider?: boolean;
  iconClass?: string;
}>;
type State = Record<string, never>;
export default class CardFieldRowComponent extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const capsClass = this.props.valueNoCaps ? '' : 'text-capitalize';
    const hoverClass = this.props.onClick ? 'hover-field border-0 rounded-lg' : '';

    return (
      <div className="cardFieldRowComponent container">
        <div className={'row align-items-center ' + hoverClass} onClick={this.props.onClick}>
          <div className="col-11">
            {this.props.title && (
              <div className="row">
                <div className="col-12  pl-0 pr-0">
                  <h6 className="cardRowTitle text-capitalize">{this.props.title}</h6>
                </div>
              </div>
            )}
            {this.props.subtitle && (
              <div className="row">
                <div className="col-12  pl-0 pr-0">
                  <h6 className="cardRowSubtitle text-capitalize">{this.props.subtitle}</h6>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 cardRowValue pl-0 pr-0">
                {this.props.valueJSX ? (
                  this.props.valueJSX
                ) : (
                  <p className={'text-dark ' + capsClass + ' ' + (this.props.description ? 'mb-0' : 'mb-3')}>
                    {this.props.value}
                  </p>
                )}
                {this.props.description && <p className="description text-muted">{this.props.description}</p>}
                {this.props.children && this.props.children}
              </div>
            </div>
          </div>
          <div className="col-1 mx-auto">{this.props.iconClass && <span className={this.props.iconClass} />}</div>
        </div>
        <div className="row">{this.props.includeDivider && <div className="card-divider w-100 mb-3" />}</div>
      </div>
    );
  }
}
