import React from 'react';
import { conf } from 'config';
import { Auth0Service } from 'services/Auth0Service';
import Util from 'util/util';

import './TopBarComponent.scss';

type Props = {
  extraClasses?: string;
  hideLogin?: boolean;
};
type State = {
  isLoggedIn: boolean | null;
};
export default class TopBarComponent extends React.Component<Props, State> {
  public state: State = {
    isLoggedIn: null,
  };

  public componentDidMount(): void {
    Auth0Service.isLoggedIn().then((isLoggedIn): void => {
      this.setState({ isLoggedIn: isLoggedIn });
    });
  }

  public render(): JSX.Element {
    return (
      <div id="topBarComponent" className={'w-100 ' + this.props.extraClasses ? this.props.extraClasses : ''}>
        {/* <ul className="navbar-nav d-md-inline-block">
        <li className="nav-item">
          <a className="nav-link py-md-0" href="mailto:semihq@semi.org"
            target="_blank" rel="noopener noreferrer"><Phone /> semihq@semi.org</a>
        </li>
        <li>
          <a className="nav-link py-md-0" href="tel:1-408-943-6900"><Phone /> 408.943.6900</a>
        </li>
        <li>
          <a className="nav-link py-md-0" href="https://twitter.com/SEMIconex" target="_blank"
            rel="noopener noreferrer"><Twitter /></a>
        </li>
        <li>
          <a className="nav-link py-md-0" href="https://www.linkedin.com/company/semi/"
            target="_blank" rel="noopener noreferrer"><Linkedin /></a>
        </li>
      </ul> */}
        <ul className="navbar-nav d-md-inline-block float-md-right">
          <li>
            <a className="nav-link py-md-0" href="https://store-us.semi.org/cart">
              Cart
            </a>
          </li>
          <li>
            <a
              className="nav-link py-md-0"
              href="https://www.semi.org/en/resources/library?search=&start=&end=&membership%5Bmember%5D=member&sort_bef_combine=created_DESC&sort_bef_combine=created_DESC"
            >
              Members Only
            </a>
          </li>
          {this.state.isLoggedIn === false && this.props.hideLogin === false && (
            <li>
              <a className="nav-link py-md-0" href={Util.routeHashToURL(conf.hash.login)}>
                Sign In
              </a>
            </li>
          )}
          {this.state.isLoggedIn === true && this.props.hideLogin === false && (
            <li>
              <a className="nav-link py-md-0" href={Util.routeHashToURL(conf.hash.logout)}>
                Sign Out
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
