export enum BallotTypes {
  // noinspection JSUnusedGlobalSymbols
  Technical = '1',
  Ratification = '2',
}

export enum VotingOptions {
  Accept = 'Accept',
  AcceptWithComments = 'Accept with Comments',
  Abstain = 'Abstain',
  AbstainWithComments = 'Abstain with Comments',
  Reject = 'Reject',
  Disapprove = 'Disapprove',
  Approve = 'Approve',
}

// The voting options for technical ballots
export const technicalOptions = [
  VotingOptions.Accept,
  VotingOptions.AcceptWithComments,
  VotingOptions.Abstain,
  VotingOptions.AbstainWithComments,
  VotingOptions.Reject,
];

// The voting options for retification ballots
export const ratificationOptions = [VotingOptions.Disapprove, VotingOptions.Approve, VotingOptions.Abstain];
